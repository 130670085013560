import { useQuery } from "@apollo/client";
import { ALL_BOOKREVIEWS, ALL_RECENTS } from "../queries";
import { Link, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import { useEffect, useState } from "react";
import NewNotify from "./NewNotify";


const RecentList = ({ token, setNotification }: { token: string | null, setNotification: (message: string, type: 'success' | 'error') => void }) => {
    const [message, setMessage] = useState<string | null>(null)
    const [messageType, setMessageType] = useState<'success' | 'error' | null>(null);
    const location = useLocation();

    useEffect(() => {
        document.title = "최근 수정"
        // if (location.pathname === "/recents") {
        //     setMessage(
        //         `2024년 10월 23일을 시작으로 서평 꾸준히 업데이트할 예정입니다`
        //     )
        //     setMessageType("success");
        //     setTimeout(() => {
        //         setMessage(null)
        //         setMessageType(null)
        //     }, 4000)
        // }
    }, [document.title]);

    const recentResult = useQuery(ALL_RECENTS)

    const padding = {
        padding: 5,
        textDecoration: "none",
        color: "white"
    }
    const maxLength = 145;

    return (
        <div style={{ margin: 10 }}>
          <NewNotify message={message} messageType={messageType} />
          <div>
            <br />
          </div>
          
          {/* ✅ Flexbox 컨테이너 추가 */}
          <div style={{
            display: 'flex',
            flexWrap: 'wrap', // 너비 부족하면 줄 바꿈
            gap: '10px', // 박스 간격 조정
            justifyContent: 'flex-start' // 왼쪽 정렬 (center 가능)
          }}>
            
            {recentResult.data?.allRecents.map((re: any) => (
              <div key={re.id} style={{   
                display: 'flex', // 내부 정렬을 위해 flex 사용
                flexDirection: 'column', // 위에서 아래로 배치
                justifyContent: 'space-between', // 내용과 버튼 간격 조정
                borderStyle: "inset", 
                padding: 10, 
                minWidth: window.innerWidth > 700 ? '30%' : '95%',  // 최소 너비
                maxWidth: window.innerWidth > 700 ? '30%' : '95%', // 한 줄에 3개씩 배치되도록 설정
                flexGrow: 1,  // 화면 크기에 맞게 조절
                boxSizing: 'border-box', // 패딩 포함한 크기 조절
              }}>
                <div style={{ marginLeft: 5, wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '40ch' }}>
                  <h4 style={{ whiteSpace: 'pre-line' }}>
                    {re.url}
                  </h4>
                  <div style={{ whiteSpace: 'break-spaces', wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '40ch' }}>
                    {re.description.length > maxLength ? re.description.substring(0, maxLength) + "..." : re.description}
                  </div>
                  <br />
                  <a href={re.url} style={{ color: "#7c73e6", textDecoration: "none" }}>
                    {re.url}{`(${re.url?.slice(0, 15)}···)`}
                  </a>
                  <br />
                  <br />
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 'auto' }}>
                    <div>{new Date(Number(re.createdAt)).toLocaleString('ko-KR')}</div>
                    <Link to={`/recents/${re.id}`} style={padding}>
                      <Button variant="contained">자세히</Button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
}


export default RecentList;