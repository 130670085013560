import React, { useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppType, PrincipleType, RecentType } from "../types";
import { FixedAppStyledBox, FixedStyledBox, HomeStyleddBox } from "./styled_components/Boxs";
import Notify from "./Notify";
import Time from "./Time";

const Home = ({ apps, principles, recents, setNotification }: {
    apps: AppType[], principles: PrincipleType[],
    recents: RecentType[],
    setNotification: (message: string, type: 'success' | 'error') => void
}) => {
    const padding = {
        textDecoration: "none",
        color: "white",
    };
    const [message, setMessage] = useState<string | null>(null)
    const [messageType, setMessageType] = useState<'success' | 'error' | null>(null);

    const canvasRef = useRef<HTMLCanvasElement | null>(null);
    const location = useLocation();



    useEffect(() => {
        document.title = "트랩위키"

        if (location.pathname === "/") {
            setMessage(
                `박스를 누르면 해당 페이지로 이동합니다.
                \n \n트랩위키는 어플리케이션의 디자인과 작동원리를 분석하고,
                \n \n분석을 위해 관련 자료(참고자료 및 서평)를 소개하며,
                \n \n해결책(디지털디톡스 및 OS)을 고민합니다.
                \n \n매달 약 1만원의 서버 비용을 줄이기 위해 불가피하게 약 30초의 사이트 접속 지연이 발생하게 되었습니다.`
            )
            setMessageType("success");
            setTimeout(() => {
                setMessage(null)
                setMessageType(null)
            }, 10000)
        }
    }, [document.title]);

    useEffect(() => {
        const canvas = canvasRef.current;

        if (!canvas) return;

        const ctx = canvas!.getContext("2d");
        if (!ctx) return;

        const renderLines = () => {
            const canvasWidth = window.screen.width * 0.4;
            const canvasHeight = 60 * principles.length + 200;

            canvas.width = canvasWidth;
            canvas.height = canvasHeight;

            ctx?.clearRect(0, 0, canvasWidth, canvasHeight);

            apps.forEach((a: AppType, appIndex: number) => {
                a?.used_principles.forEach((p: PrincipleType, principleIndex: number) => {
                    const appY = (appIndex + 1) * 64 - 26;
                    const realPrincipleIndex = principles.findIndex(k => k.id === p.id);
                    const principleY = (realPrincipleIndex + 1) * 64 - 26;
                    let pickedColor;

                    switch (a.appType) {
                        case 'SOCIALMEDIA':
                            pickedColor = '#8ef6e4';
                            break;
                        case 'COMMUNITY':
                            pickedColor = '#5e63b6';
                            break;
                        case 'NEWS':
                            pickedColor = '#118a7e';
                            break;
                        case 'OTT':
                            pickedColor = '#ff847c';
                            break;
                        case 'VIDEO':
                            pickedColor = '#dc2f2f';
                            break;
                        case 'GAME':
                            pickedColor = '#fac70b';
                            break;
                        default:
                            pickedColor = '#d55b3e';
                            break;
                    }

                    ctx?.beginPath();
                    ctx?.moveTo(0, principleY);

                    if (document.documentElement.scrollTop < 20)
                        ctx?.lineTo(canvasWidth, document.documentElement.scrollTop + appY - 5)
                    else if (document.documentElement.scrollTop < 40)
                        ctx?.lineTo(canvasWidth, document.documentElement.scrollTop + appY - 25)
                    else if (document.documentElement.scrollTop < 60)
                        ctx?.lineTo(canvasWidth, document.documentElement.scrollTop + appY - 45)
                    else if (document.documentElement.scrollTop < 80)
                        ctx?.lineTo(canvasWidth, document.documentElement.scrollTop + appY - 65)
                    else if (document.documentElement.scrollTop < 90)
                        ctx?.lineTo(canvasWidth, document.documentElement.scrollTop + appY - 75)
                    else if (document.documentElement.scrollTop < 100)
                        ctx?.lineTo(canvasWidth, document.documentElement.scrollTop + appY - 85)
                    else if (document.documentElement.scrollTop < 110)
                        ctx?.lineTo(canvasWidth, document.documentElement.scrollTop + appY - 95)
                    else if (document.documentElement.scrollTop > 120)
                        ctx?.lineTo(canvasWidth, document.documentElement.scrollTop + appY - 105)
                    // else if (document.documentElement.scrollTop < 130)
                    //     ctx?.lineTo(canvasWidth, document.documentElement.scrollTop + appY - 105)
                    // else if (document.documentElement.scrollTop > 140)
                    //     ctx?.lineTo(canvasWidth, document.documentElement.scrollTop + appY - 105)
                    // else ctx?.lineTo(canvasWidth, document.documentElement.scrollTop + appY - 60)
                    ctx.strokeStyle = pickedColor;
                    ctx?.stroke();
                });
            });
        };

        renderLines();

        // Handle window resize to keep the canvas responsive
        const handleResize = () => {
            renderLines();
        };

        const handleScroll = () => {
            renderLines(); // Update canvas on scroll

            // You can also set appLocationTop or do other actions here if needed
            // const newAppLocationTop = document.documentElement.scrollTop;
            // setAppLocationTop(newAppLocationTop);
        };

        window.addEventListener("resize", handleResize);
        window.addEventListener("scroll", handleScroll); // Listen for scroll events

        return () => {
            window.removeEventListener("resize", handleResize);
            window.removeEventListener("scroll", handleScroll); // Remove scroll event listener
        };
    }, [apps, principles]);


    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Notify message={message} messageType={messageType} />
            <div style={{
    display: 'flex',
    gap: '10px',
    marginBottom: '10px',
    marginLeft: '10px',
    overflowX: 'auto',
    whiteSpace: 'nowrap',
    width: '100%',
    paddingBottom: '5px',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
    alignItems: 'center', // 세로 정렬
    position: 'relative'
}}>
    {recents?.map((r: RecentType) => r && (
        <div key={r.id} style={{ display: 'inline-block', marginTop: '10px' }}>
            <Link to={`${r.url}`}
                style={{
                    padding: '5px 10px',
                    border: '1px solid #ddd',
                    borderRadius: '5px',
                    textDecoration: "none",
                    color: "#7c73e6",
                    display: 'inline-block',
                    backgroundColor: "#f9f9f9",
                    transition: "0.3s",
                }}
                onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#e0e0ff")}
                onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#f9f9f9")}
            >
                {`${r.description} (${new Date(Number(r.createdAt)).toLocaleDateString('ko-KR')})`}
            </Link>
        </div>
    ))}
    {/* "최근 수정" 버튼을 스타일링 */}
    <Link to={'/recents'}
        style={{
            padding: '6px 15px',
            borderRadius: '8px',
            backgroundColor: '#7c73e6',
            color: 'white',
            textDecoration: 'none',
            fontWeight: 'bold',
            display: 'inline-block',
            transition: '0.3s',
            marginRight: '15px',
            marginTop: '10px',
            boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)'
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = '#5a50c8')}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = '#7c73e6')}
    >
        ··· 최근 수정 더보기
    </Link>
</div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <div style={{ flex: '1', marginLeft: '1%' }}>
                {principles.map((p: PrincipleType) => p && (
                    <div key={p.id} style={{ marginTop: '10px' }}>
                        <Link to={`/principles/${p.names.koreanName}`} key={p.id} style={padding}>
                            <FixedStyledBox>
                                <div>{p.names.koreanName}</div>
                            </FixedStyledBox>
                        </Link>
                    </div>
                ))}
            </div>

            <canvas ref={canvasRef} style={{ flex: '1' }} />
            <div style={{ flex: '1', marginRight: '1%', position: 'sticky', top: 15 }}>
                {apps?.map((a: AppType) => a && (
                    <div key={a.id} style={{ marginTop: '10px' }}>
                        <Link to={`/apps/${a.names.koreanName}`} style={padding}>
                            <FixedAppStyledBox appType={a.appType}>
                                <div>{a.names.koreanName}</div>
                            </FixedAppStyledBox>
                        </Link>
                    </div>
                ))}
                <br/>

                {/* style={{display: 'flex', justifyContent: 'space-eveny'}} */}
                <div >
                <Link to={`/bookreviews`} style={padding}>
                    <HomeStyleddBox appType={"bookreviews"}>
                        <div>서평</div>
                    </HomeStyleddBox>
                </Link>
                <Link to={`/references`} style={padding}>
                    <HomeStyleddBox appType={"references"}>
                        <div>참고자료</div>
                    </HomeStyleddBox>
                </Link>
                <Link to={`/digitaldetox`} style={padding}>
                    <HomeStyleddBox appType={"digitaldetox"}>
                        <div>디지털 디톡스</div>
                    </HomeStyleddBox>
                </Link>
                <Link to={`/os`} style={padding}>
                    <HomeStyleddBox appType={"os"}>
                        <div>운영체제별 <br/>대처법</div>
                    </HomeStyleddBox>
                </Link>
                </div>
            </div>
            {/* <div>{recents?.map((r: RecentType) => r && (r.url))}</div> */}
        </div>
        </div>
    );
};

export default Home;


// <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
//             <Notify message={message} messageType={messageType} />
//             <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginBottom: '10px' }}>
//                     {recents?.map((r: RecentType) => r && (<div key={r.id} style={{ marginTop: '10px'}}>
//                         <Link to={`${r.url}`}>{r.description}</Link>
//                     </div>)
//                  )}
//                 </div>
//             <div style={{ flex: '1', marginLeft: '1%' }}>
//                 {principles.map((p: PrincipleType) => p && (
//                     <div key={p.id} style={{ marginTop: '10px' }}>
//                         <Link to={`/principles/${p.names.koreanName}`} key={p.id} style={padding}>
//                             <FixedStyledBox>
//                                 <div>{p.names.koreanName}</div>
//                             </FixedStyledBox>
//                         </Link>
//                     </div>
//                 ))}
//             </div>

//             <canvas ref={canvasRef} style={{ flex: '1' }} />

//             <div style={{ flex: '1', marginRight: '1%', position: 'sticky', top: 0 }}>
//                 {apps?.map((a: AppType) => a && (
//                     <div key={a.id} style={{ marginTop: '10px' }}>
//                         <Link to={`/apps/${a.names.koreanName}`} style={padding}>
//                             <FixedAppStyledBox appType={a.appType}>
//                                 <div>{a.names.koreanName}</div>
//                             </FixedAppStyledBox>
//                         </Link>
//                     </div>
//                 ))}
//                 <br/>

//                 {/* style={{display: 'flex', justifyContent: 'space-eveny'}} */}
//                 <div >
//                 <Link to={`/bookreviews`} style={padding}>
//                     <HomeStyleddBox appType={"bookreviews"}>
//                         <div>서평</div>
//                     </HomeStyleddBox>
//                 </Link>
//                 <Link to={`/references`} style={padding}>
//                     <HomeStyleddBox appType={"references"}>
//                         <div>참고자료</div>
//                     </HomeStyleddBox>
//                 </Link>
//                 <Link to={`/digitaldetox`} style={padding}>
//                     <HomeStyleddBox appType={"digitaldetox"}>
//                         <div>디지털 디톡스</div>
//                     </HomeStyleddBox>
//                 </Link>
//                 <Link to={`/os`} style={padding}>
//                     <HomeStyleddBox appType={"os"}>
//                         <div>운영체제별 <br/>대처법</div>
//                     </HomeStyleddBox>
//                 </Link>
//                 </div>
//             </div>
//             {/* <div>{recents?.map((r: RecentType) => r && (r.url))}</div> */}
//         </div>