import { useQuery } from '@apollo/client'
import PrincipleForm from './components/PrincipleForm';
import Notify from './components/Notify';
import { ALL_APPS, ALL_PRINCIPLES, ALL_RECENTS, ALL_RECENTS_AT_HOME } from './queries';
import { useState } from 'react';
import Navigation from './components/navigation/Navigation';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppForm from './components/AppForm';
import LoginForm from './components/Login';
import SignUp from './components/SignUp';
//import AppList from './components/AppList';
import PrincipleInfo from './components/PrincipleInfo';
//import PrincipleList from './components/PrincipleList';
import AppInfo from './components/AppInfo';
import Home from './components/Home';
import ForXml from './components/ForXml';
import About from './components/About';
import OS from './components/OS';
import Suggestion from './components/Suggestion';
import DigitalDetox from './components/DigitalDetox';
import { StyledDiv } from './style';
import Loading from './components/Loading';
import Donation from './components/Donation';
//import Reference from './components/ReferenceList';
import RefForm from './components/RefForm';
import RefInfo from './components/RefInfo';
import ReferenceList from './components/ReferenceList';
import BookReviewList from './components/BookReviewList';
import BookReviewForm from './components/BookReviewForm';
import BookReivewInfo from './components/BookReviewInfo';
import DisableUser from './components/DisableUser';
import RecentForm from './components/RecentForm';
import InterviewForm from './components/InterviewForm';
import InterviewList from './components/InterviewList';
import InterviewInfo from './components/InterviewInfo';
import RecentList from './components/RecentList';
import RecentInfo from './components/RecentInfo';




const App = () => {
  const [token, setToken] = useState<string | null>(localStorage.getItem("tw-user-token") || null)
  const [message, setMessage] = useState<string | null>(null)
  const [messageType, setMessageType] = useState<'success' | 'error' | null>(null);
  const [notifyStyle, setNotifyStyle] = useState<boolean>(true);

  const principleResult = useQuery(ALL_PRINCIPLES)
  const appResult = useQuery(ALL_APPS)
  const recentAtHomeList = useQuery(ALL_RECENTS_AT_HOME)
  const recentList = useQuery(ALL_RECENTS)

  if (principleResult.loading) {
    return <Loading />
  }

  if (appResult.loading) {
    return <Loading />
  }

  if (recentList.loading) {
    return <Loading />
  }

  const notify = (message: string, type: 'success' | 'error') => {
    setMessage(message)
    setMessageType(type);
    setNotifyStyle(notifyStyle);
    setTimeout(() => {
      setMessage(null)
      setMessageType(null)
    }, 5000)
  }

  return (
    <StyledDiv>
      <Router>
        <Navigation setToken={setToken} token={token} setNotification={notify} />
        <Notify message={message} messageType={messageType} />
        <Routes>
          <Route path="/" element={<Home principles={principleResult.data.allPrinciples} apps={appResult.data.allApps} 
          recents={recentAtHomeList.data?.allRecentsAtHome}
          setNotification={notify} />} />
          {/* <Route path="/principles"
            element={<PrincipleList principles={principleResult.data.allPrinciples} setNotification={notify} token={token} />}>
          </Route> */}
          <Route path="/principles/:koreanName" element={<PrincipleInfo setNotification={notify} token={token} />}></Route>
          <Route path="/principles/:searchToId" element={<PrincipleInfo setNotification={notify} token={token} />}></Route>
          <Route path="/new_principle" element={<PrincipleForm setNotification={notify} />} />
          {/* <Route path="/apps" element={<AppList apps={appResult.data.allApps} />}></Route> */}
          <Route path="/apps/:koreanName" element={<AppInfo setNotification={notify} token={token} />}></Route>
          <Route path="/references/:id" element={<RefInfo setNotification={notify} token={token} />}></Route>
          <Route path="/new_app" element={<AppForm setNotification={notify} />} />
          <Route path="/new_reference" element={<RefForm setNotification={notify} />} />
          <Route path="/new_bookreview" element={<BookReviewForm setNotification={notify}/>}/>
          <Route path="/bookreviews/:id" element={<BookReivewInfo setNotification={notify} token={token}/>}></Route>
          <Route path="/login" element={<LoginForm setNotification={notify} setToken={setToken} />} />
          <Route path="/signup" element={<SignUp setNotification={notify} />} />
          <Route path="/disable_user" element={<DisableUser setNotification={notify}/>}></Route>
          <Route path="/suggestion" element={<Suggestion />} />
          <Route path="/about" element={<About />} />
          <Route path="/os" element={<OS />} />
          <Route path="/digitaldetox" element={<DigitalDetox setNotification={notify}/>} />
          <Route path="/donation" element={<Donation />} />
          <Route path="/references" element={<ReferenceList token={token} setNotification={notify}/>} />
          <Route path="/bookreviews" element={<BookReviewList token={token} setNotification={notify}/>} />
          <Route path="interviews" element={<InterviewList token={token} setNotification={notify}/>}/>
          <Route path="/new_recent" element={<RecentForm setNotification={notify}/>} />
          <Route path="/new_interview" element={<InterviewForm setNotification={notify}/>} />
          <Route path="/interviews/:id" element={<InterviewInfo setNotification={notify} token={token}/>}></Route>
          <Route path="/recents" element={<RecentList token={token} setNotification={notify}/>}></Route>
          <Route path="/recents/:id" element={<RecentInfo setNotification={notify} token={token}/>}></Route>
          {/* <Route path="/:id/change_principle" element={<DescriptionForm setError={notify} koreanName={}/>}/> */}
          {/* <Route
            path="/login"
            element={
              <LoginForm
                setToken={setToken}
                setError={notify}
              />
            }
          />
          <Route path="/newbook" element={<NewBook setError={notify} />} />
          
          <Route
            path="/recommend"
            element={<Recommend token={token} />} /> */}
          <Route path="/for_xml" element={<ForXml principles={principleResult.data.allPrinciples} apps={appResult.data.allApps} />} />
        </Routes>
      </Router>
    </StyledDiv>
  )
}

export default App