import { useQuery } from "@apollo/client";
import { ALL_REFS } from "../queries";
import { Link, useLocation } from "react-router-dom";
import Button from '@mui/material/Button';
import { useEffect, useState } from "react";
import NotifyRef from "./NewNotify";
import NewNotify from "./NewNotify";


const ReferenceList = ({ token, setNotification }: { token: string | null, setNotification: (message: string, type: 'success' | 'error') => void }) => {
    const [message, setMessage] = useState<string | null>(null)
    const [messageType, setMessageType] = useState<'success' | 'error' | null>(null);
    const location = useLocation();

    useEffect(() => {
        document.title = "참고자료"

        if (location.pathname === "/references") {
            setMessage(
                `트랩위키 각각의 페이지 하단에 참고자료를 업데이트하고 있습니다.\n \n이 페이지에서는 어느 한 주제에 속하지 않는 참고자료를 소개합니다.`
            )
            setMessageType("success");
            setTimeout(() => {
                setMessage(null)
                setMessageType(null)
            }, 6000)
        }
    }, [document.title]);

    const referenceResult = useQuery(ALL_REFS)

    const padding = {
        padding: 5,
        textDecoration: "none",
        color: "white"
    }

    const maxLength = 200;

    return (
        <div style={{ margin: 10 }}>
          <NewNotify message={message} messageType={messageType} />
          <div>
            <br />
          </div>
          
          {/* ✅ Flexbox 컨테이너 추가 */}
          <div style={{
            display: 'flex',
            flexWrap: 'wrap', // 너비 부족하면 줄 바꿈
            gap: '10px', // 박스 간격 조정
            justifyContent: 'flex-start' // 왼쪽 정렬 (center 가능)
          }}>
            
            {referenceResult.data?.allReferences.map((ref: any) => (
              <div key={ref.id} style={{   
                display: 'flex', // 내부 정렬을 위해 flex 사용
                flexDirection: 'column', // 위에서 아래로 배치
                justifyContent: 'space-between', // 내용과 버튼 간격 조정
                borderStyle: "inset", 
                padding: 10, 
                minWidth: window.innerWidth > 700 ? '30%' : '95%',  // 최소 너비
                maxWidth: window.innerWidth > 700 ? '30%' : '95%', // 한 줄에 3개씩 배치되도록 설정
                flexGrow: 1,  // 화면 크기에 맞게 조절
                boxSizing: 'border-box', // 패딩 포함한 크기 조절
              }}>
                <div style={{ marginLeft: 5, wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '40ch' }}>
                  <h4 style={{ whiteSpace: 'pre-line' }}>
                    {ref.title + " | "+ ref.author}
                  </h4>
                  <div style={{ whiteSpace: 'break-spaces', wordWrap: 'break-word', overflowWrap: 'break-word', maxWidth: '40ch' }}>
                    {ref.description.length > maxLength ? ref.description.substring(0, maxLength) + "..." : ref.description}
                  </div>
                  <br />
                  <a href={ref.link} style={{ color: "#7c73e6", textDecoration: "none" }}>
                    {ref.linkTitle}{`(${ref.link?.slice(0, 15)}···)`}
                  </a>
                  <br />
                  <br />
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 'auto' }}>
                    <div>{new Date(Number(ref.createdAt)).toLocaleString('ko-KR')}</div>
                    <Link to={`/references/${ref.id}`} style={padding}>
                      <Button variant="contained">자세히</Button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
      
          </div>
        </div>
      );
}


export default ReferenceList;


// (<div style={{ margin: 10 }}>
//     <NewNotify message={message} messageType={messageType} />
// <div>
// <br/>
// </div>
// {referenceResult.data?.allReferences.map((ref: any) => {

// return <div key={ref.id} style={{   display: 'inline-block', 
//     borderStyle: "inset", 
//     marginBottom: 20, 
//     padding: 10, 
//     maxWidth: window.innerWidth > 700 ? '90%' : '90%', // Responsive width
//     marginRight: window.innerWidth > 700 ? '2%' : '2%' }}>
//     <div style={{marginLeft: 5, wordWrap: 'break-word', overflowWrap: 'break-word',maxWidth: '40ch'}}>
//     <h4>{`${ref.title} |`} <br /> {ref.author}</h4>
//     <div style={{ whiteSpace: 'break-spaces', wordWrap: 'break-word', overflowWrap: 'break-word',maxWidth: '40ch' }}>
//     {ref.description.length > maxLength ? ref.description.substring(0, maxLength) + "..."  : ref.description }
//     </div>
//     <br />
//     <a href={ref.link} style={{ color: "#7c73e6", textDecoration: "none" }}>{ref.linkTitle}{`(${ref.link?.slice(0, 15)}···)`}</a>
//     <br/>
//     <br/>
//     <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: 'auto' }}>
//     <div>{new Date(Number(ref.createdAt)).toLocaleString('ko-KR')}</div>
//     {<Link to={`/references/${ref.id}`} style={padding}>

 

//     <Button variant="contained" >자세히</Button>
//         {/* <RefStyledBox>{ref.author} {ref.title}</RefStyledBox> */}
//     </Link>}
//     </div>
//     </div>
// </div>
// })

// }</div>)