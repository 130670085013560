import { useState, SyntheticEvent, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { ADD_RECENT, ALL_RECENTS } from '../queries'
import { Editor } from './Editor/Editor';
import { $getRoot, LexicalEditor } from 'lexical';
import { CLEAR_EDITOR_COMMAND } from 'lexical';
import { useNavigate } from 'react-router-dom';


const RecentForm = ({ setNotification }: { setNotification: (message: string, type: 'success' | 'error') => void }) => {
  const [url, setUrl] = useState('')
  const [description, setDescription] = useState('')

  const editorRef = useRef<LexicalEditor | null>();

  const [createRecent] = useMutation(ADD_RECENT, {
    update: (cache, response) => {
      cache.updateQuery({ query: ALL_RECENTS }, ({ allRecents }) => {
        return {
          allRecents: allRecents?.concat(response.data.addRecent),
        }
      })
    },
  })
  const navigate = useNavigate()
  const submit = async (event: SyntheticEvent) => {
    event.preventDefault()
    
    const latestEditorState = editorRef.current?.getEditorState();
    const textContent = latestEditorState?.read(() =>
      $getRoot().getTextContent()
    );
    const description = textContent;

    const result = await createRecent({ variables: { url, description } })
    if(result.data) {
    setUrl('')
    setDescription('')
    setNotification(`${url} 추가되었습니다`, 'success')
    navigate('/')
    }

    if (editorRef.current !== null)
    editorRef.current?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
  }

  return (
    <div style={{marginLeft: '1.5%', marginRight: '1.5%'}}>
      <h2>추가하기</h2>
      <form onSubmit={submit}>
        <div>
          url <input value={url}
            onChange={({ target }) => setUrl(target.value)}
          />
        </div>
        <div  className="editorWrapper">
          {/* <input value={description}
            onChange={({ target }) => setDescription(target.value)}
          /> */}
          <Editor ref={editorRef} />
        </div>
        {/* <div>어플리케이션: {apps.join(' ')}</div> */}
        {/* <div>
          앱 <input value={app}
            onChange={({ target }) => setApp(target.value)}
          />
          <button onClick={addApp} type="button">
            앱 추가
          </button>
        </div> */}
      <hr/>
      <button type='submit' id='submit-button'>추가!</button>
      </form>
    </div>
  )
}

export default RecentForm;