import { useRef, useEffect, SyntheticEvent, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Editor } from './Editor/Editor';
import { $getRoot, LexicalEditor } from 'lexical';
import { RecentType } from '../types';
import { ALL_RECENTS, DELETE_RECENT, EDIT_RECENT } from '../queries';
import { useNavigate } from 'react-router-dom';

const EditRecent = ({ setNotification, recent, setOpenEditor }:
  { setNotification: (message: string, type: 'success' | 'error') => void, recent: RecentType, setOpenEditor: React.Dispatch<React.SetStateAction<boolean>> }) => {

  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');

  const navigate = useNavigate()

  const [editRecent, result] = useMutation(EDIT_RECENT, {
    onError: (error) => {
      //const messages = error.graphQLErrors[0].message
      setNotification(error.message, 'error');
    },
  })
  const [deleteRecent] = useMutation(DELETE_RECENT, {
    refetchQueries: [{ query: ALL_RECENTS }],
    onError: (error) => {
      setNotification(error.message, 'error');
    }
  })
  const editorRef = useRef<LexicalEditor | null>(null);

  const submit = (event: SyntheticEvent) => {
    event.preventDefault()
    const latestEditorState = editorRef.current?.getEditorState();
    const textContent = latestEditorState?.read(() =>
      $getRoot().getTextContent()
    );
    const description = textContent;
    
    editRecent({
      variables: {
        id: recent.id, url: url, description: description
      }
    })

    if (!result.error) {
      setOpenEditor(false);
    }
    setNotification('수정되었습니다.', 'success');
  }

  const submitDeleteRecent = async (id: number) => {
    const result = await deleteRecent
      ({
        variables: {
          id: id
        }
      })

    if (result.data) setNotification('삭제되었습니다.', 'success');

    navigate('/')
  }

  useEffect(() => {
    if (result.data && result.data.editDescription === null) {
      setNotification('Not Found', 'error')
    }
    setUrl(recent.url.toString())
  }, [result.data]) // eslint-disable-line 

  return (
    <div>
      <h2>{recent.url} Recent 수정하기</h2>

      <form onSubmit={submit}>
        <div>
          url <input
            value={url}
            onChange={({ target }) => setUrl(target.value)}
          />
        </div>      
        <div className="editorWrapper">
          {/* <input
            value={phone}
            onChange={({ target }) => setPhone(target.value)}
          /> */}
          <Editor ref={editorRef} description={recent.description} />
        </div>
        <button type='submit'>수정</button>
      </form>
      <button type='button' onClick={() => setOpenEditor(false)}>취소</button>
      <hr />
      <button onClick={() => submitDeleteRecent(recent.id)}>{"삭제"}</button>
      <hr />
    </div>
  )
}

export default EditRecent;