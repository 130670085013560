import { gql } from '@apollo/client'

const PRINCIPLE_DETAILS = gql`
fragment PrincipleDetails on Principle {
  id
  names {
    koreanName
    englishName
  }
  description
  countermeasure
}
`

const APP_DETAILS = gql`
fragment AppDetails on App {
  id
  names {
    koreanName
    englishName
  }
  description
  alternative
  countermeasure
  similarApp
}
`

const REF_DETAILS = gql`
fragment RefDetails on Reference {
  id
  title
  author
  description
  link
  linkTitle
}
`

const BOOKREVIEW_DETAILS = gql`
fragment BookReviewDetails on BookReview {
  id
  bookTitle
  reviewTitle
  author
  description
  imageUrl
}
`

const RECENT_DETAILS = gql`
fragment RecentDetails on Recent {
  id
  url
  description
}
`

const INTERVIEW_DETAILS = gql`
fragment InterviewDetails on Interview {
  id
  title
  profile
  description
}
`

export const CREATE_PRINCIPLE = gql`
mutation createPrinciple($koreanName: String!, $englishName: String!, $description: String) {
  addPrinciple(
    koreanName: $koreanName,
    englishName: $englishName,
    description: $description,
  ) {
    ...PrincipleDetails
  }
}
${PRINCIPLE_DETAILS}
`
export const ALL_PRINCIPLES = gql`
query allPrinciples {
  allPrinciples {
    names {
      koreanName
      englishName
    }
    id
    using_apps {
      names {
        koreanName
        englishName
      }
      id
    }
  }
}
`
export const ALL_PRINCIPLES_WITHOUT_APPID = gql`
query allPrinciplesWithoutAppId($appId: ID!) {
  allPrinciplesWithoutAppId(appId: $appId) {
    names {
      koreanName
      englishName
    }
    id
  }
}
`

export const FIND_PRINCIPLE = gql`
  query findPrinciple($koreanName: String!) {
    findPrinciple(koreanName: $koreanName) {
      names {
        koreanName
        englishName
       }
      description
      countermeasure
      using_apps {
        names {
          koreanName
          englishName
        }
        appType
        app_principles {
          comment
        }
        id
      }
      prin_refs {
        author
        title
        description
        link
        linkTitle
        id
      }
      id
      createdAt
      updatedAt
    }
  }
`

export const EDIT_PRINCIPLE = gql`
  mutation editPrinciple($id: ID!, $description: String!) {
    editPrinciple(id: $id, description: $description) {
      ...PrincipleDetails
    }
  }
  ${PRINCIPLE_DETAILS}
`

export const ALL_APPS = gql`
query {
  allApps {
    names {
      koreanName
      englishName
    }
    id
    appType
    used_principles {
      names {
        koreanName
        englishName
      }
      id
    }
  }
}
`
export const FIND_APP = gql`
query findApp($koreanName: String!) {
  findApp(koreanName: $koreanName) {
    names {
      koreanName
      englishName
    }
    description
    appType
    alternative
    countermeasure
    similarApp
    used_principles {
      names {
        koreanName
        englishName
      }
      description
      id
      app_principles {
        comment
      }
    }
    app_refs {
      author
      title
      description
      link
      linkTitle
      id
    }
    id
    createdAt
    updatedAt
  }
}
`

export const TEMP_FIND_APP = gql`
query findApp($koreanName: String!) {
  findApp(koreanName: $koreanName) {
    names {
      koreanName
      englishName
    }
    description
    appType
    alternative
    countermeasure
    used_principles {
      names {
        koreanName
        englishName
      }
      description
      id
      app_principles {
        comment
      }
    }
    app_refs {
      author
      title
      description
      link
      linkTitle
      id
    }
    id
  }
}
`

export const CREATE_APP = gql`
mutation addApp($koreanName: String!, $englishName: String!, $description: String, $appType: String!) {
  addApp(
    koreanName: $koreanName,
    englishName: $englishName,
    description: $description,
    appType: $appType
  ) {
    ...AppDetails
  }
}
${APP_DETAILS}
`


export const EDIT_APP = gql`
  mutation editApp($id: ID!, $description: String!) {
    editApp(id: $id, description: $description) {
      ...AppDetails
    }
  }
  ${APP_DETAILS}
`

export const EDIT_APP_ALTERNATIVE = gql`
mutation editAppAlternative($id: ID!, $alternative: String!) {
  editAppAlternative(id: $id, alternative: $alternative) {
    ...AppDetails
  }
}
${APP_DETAILS}
`

export const EDIT_PRINCIPLE_COUNTER = gql`
mutation editPrincipleCounter($id: ID!, $countermeasure: String!) {
  editPrincipleCounter(id: $id, countermeasure: $countermeasure) {
    ...PrincipleDetails
  }
}
${PRINCIPLE_DETAILS}
`

export const EDIT_SIMILAR_APP = gql`
mutation editSimilarApp($id: ID!, $similarApp: String!) {
  editSimilarApp(id: $id, similarApp: $similarApp) {
    ...AppDetails
  }
}
${APP_DETAILS}
`

export const EDIT_PRINCIPLE_KOREANNAME = gql`
mutation editPrincipleKoreanName($id: ID!, $koreanName: String!) {
  editPrincipleKoreanName(id: $id, koreanName: $koreanName) {
    ...PrincipleDetails
  }
}
${PRINCIPLE_DETAILS}
`

export const EDIT_PRINCIPLE_ENGLISHNAME = gql`
mutation editPrincipleEnglishName($id: ID!, $englishName: String!) {
  editPrincipleEnglishName(id: $id, englishName: $englishName) {
    ...PrincipleDetails
  }
}
${PRINCIPLE_DETAILS}
`

export const EDIT_APP_COUNTER = gql`
mutation editAppCounter($id: ID!, $countermeasure: String!) {
  editAppCounter(id: $id, countermeasure: $countermeasure) {
    ...AppDetails
  }
}
${APP_DETAILS}
`

export const CONNECT_PRINCIPLE_TO_APP = gql`
mutation connectPrincipleToApp($appId: ID!, $principleId: ID!) {
  connectPrincipleToApp(appId: $appId, principleId: $principleId) {
    ids {
      appId
      principleId
    }
  }
}
`

export const LOGIN = gql`
  mutation login($username: String!, $password: String!) {
    login(username: $username, password: $password)  {
      value
    }
  }
`

export const LOGOUT_ONE = gql`
mutation logoutOne {
  logoutOne
}
`

export const LOGOUT_ALL = gql`
mutation logoutAll {
  logoutAll
}
`

export const CREATE_USER = gql`
mutation createUser($username: String!, $password: String!, $name: String!) {
  createUser(username: $username, password: $password, name: $name) {
    username
    name
  }
}
`

export const DISABLE_USER = gql`
mutation disableUser($username: String!) {
  disableUser(username: $username) {
    username
  }
}`

export const PAGE_STRING = gql`
query {
  pageString
}
`

export const ADD_APP_PRINCIPLE_COMMENT = gql`
mutation addAppPrincipleComment($appId: ID!, $principleId: ID!, $comment: String!) {
  addAppPrincipleComment(appId: $appId, principleId: $principleId, comment: $comment) {
    comment
  }
}`

export const ADD_APP_REF = gql`
mutation addAppRef($author: String, $title: String!, $description: String, $link: String, $linkTitle: String, $appId: ID!) {
  addAppRef(author: $author, title: $title, description: $description, link: $link, linkTitle: $linkTitle, appId: $appId) {
    id
  }
}`

export const EDIT_APP_REF = gql`
mutation editAppRef($author: String, $title: String, $description: String, $link: String, $linkTitle: String, $id: ID!) {
  editAppRef(author: $author, title: $title, description: $description, link: $link, linkTitle: $linkTitle, id: $id) {
    id
  }
}`

export const DELETE_APP_REF = gql`
mutation deleteAppRef($id: ID!) {
  deleteAppRef(id: $id) {
    id
  }
}`


export const ADD_PRIN_REF = gql`
mutation addPrinRef($author: String, $title: String!, $description: String, $link: String, $linkTitle: String, $principleId: ID!) {
  addPrinRef(author: $author, title: $title, description: $description, link: $link, linkTitle: $linkTitle, principleId: $principleId) {
    id
  }
}`

export const EDIT_PRIN_REF = gql`
mutation editPrinRef($author: String, $title: String, $description: String, $link: String, $linkTitle: String, $id: ID!) {
  editPrinRef(author: $author, title: $title, description: $description, link: $link, linkTitle: $linkTitle, id: $id) {
    id
  }
}`

export const DELETE_PRINCIPLE = gql`
mutation deletePrinciple($id: ID!) {
  deletePrinciple(id: $id) {
    id
  }
}`

export const ADD_REF = gql`
mutation addRef($author: String, $title: String!, $description: String, $link: String, $linkTitle: String) {
  addRef(author: $author, title: $title, description: $description, link: $link, linkTitle: $linkTitle) {
    author
    title
    description
    link
    linkTitle
    id
  }
}`

export const DELETE_REF = gql`
mutation deleteRef($id: ID!) {
  deleteRef(id: $id) {
    id
  }
}`

export const EDIT_REF = gql`
mutation editRef($author: String, $title: String, $description: String, $link: String, $linkTitle: String, $id: ID!) {
  editRef(author: $author, title: $title, description: $description, link: $link, linkTitle: $linkTitle, id: $id) {
    ...RefDetails
  }
}
${REF_DETAILS}
`
export const ALL_REFS = gql`
query {
  allReferences {
    author
    title
    description
    link
    linkTitle
    id
    createdAt
  }
}
`
export const FIND_REF = gql`
query findReference($id: Int!) {
  findReference(id: $id) {
    author
    title
    description
    link
    linkTitle
    id
    createdAt
    updatedAt
  }
}
`

export const ADD_BOOKREVIEW = gql`
mutation addBookReview($author: String, $bookTitle: String!, $reviewTitle: String, $description: String, $imageUrl: String) {
  addBookReview(author: $author, bookTitle: $bookTitle, reviewTitle: $reviewTitle, description: $description, imageUrl: $imageUrl) {
    author
    bookTitle
    reviewTitle
    description
    imageUrl
    id
  }
}`

export const DELETE_BOOKREVIEW = gql`
mutation deleteBookReview($id: ID!) {
  deleteBookReview(id: $id) {
    id
  }
}`

export const EDIT_BOOKREVIEW = gql`
mutation editBookReview($author: String, $bookTitle: String, $reviewTitle: String, $description: String, $imageUrl: String, $id: ID!) {
  editBookReview(author: $author, bookTitle: $bookTitle, reviewTitle: $reviewTitle, description: $description, imageUrl: $imageUrl, id: $id) {
    ...BookReviewDetails
  }
}
${BOOKREVIEW_DETAILS}
`

export const ALL_BOOKREVIEWS = gql`
query {
  allBookReviews {
    author
    bookTitle
    reviewTitle
    description
    imageUrl
    id
    createdAt
  }
}
`
export const FIND_BOOKREVIEW = gql`
query findBookReview($id: Int!) {
  findBookReview(id: $id) {
    author
    bookTitle
    reviewTitle
    description
    imageUrl
    id
    createdAt
    updatedAt
  }
}
`

export const ALL_RECENTS = gql`
query {
  allRecents {
    url
    description
    id
    createdAt
  }
}
`

export const ALL_RECENTS_AT_HOME = gql`
query {
  allRecentsAtHome {
    url
    description
    id
    createdAt
  }
}
`

export const FIND_RECENT = gql`
query findRecent($id: Int!) {
  findRecent(id: $id) {
    url
    description
    id
    createdAt
    updatedAt
  }
}
`

export const ADD_RECENT = gql`
mutation addRecent($url: String, $description: String) {
  addRecent(url: $url, description: $description) {
    url
    description
    id
  }
}`

export const DELETE_RECENT = gql`
mutation deleteRecent($id: ID!) {
  deleteRecent(id: $id) {
    id
  }
}`

export const EDIT_RECENT = gql`
mutation editRecent($url: String, $description: String, $id: ID!) {
  editRecent(url: $url, description: $description, id: $id) {
    ...RecentDetails
  }
}
${RECENT_DETAILS}
`

export const ALL_INTERVIEWS = gql`
query {
  allInterviews {
    title
    profile
    description
    id
    createdAt
  }
}
`

export const FIND_INTERVIEW = gql`
query findInterview($id: Int!) {
  findInterview(id: $id) {
    title
    profile
    description
    id
    createdAt
    updatedAt
  }
}
`

export const ADD_INTERVIEW = gql`
mutation addInterview($title: String!, $profile: String, $description: String) {
  addInterview(title: $title, profile: $profile, description: $description) {
    title
    profile
    description
    id
  }
}`

export const DELETE_INTERVIEW = gql`
mutation deleteInterview($id: ID!) {
  deleteInterview(id: $id) {
    id
  }
}`

export const EDIT_INTERVIEW = gql`
mutation editInterview($title: String!, $profile: String, $description: String, $id: ID!) {
  editInterview(title: $title, profile: $profile, description: $description, id: $id) {
    ...InterviewDetails
  }
}
${INTERVIEW_DETAILS}
`