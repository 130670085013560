import { useRef, useEffect, SyntheticEvent, useState } from 'react'
import { useMutation } from '@apollo/client'
import { Editor } from './Editor/Editor';

import { $getRoot, LexicalEditor } from 'lexical';
import { InterviewType } from '../types';
import { ALL_INTERVIEWS, DELETE_INTERVIEW, EDIT_INTERVIEW } from '../queries';
import { useNavigate } from 'react-router-dom';

const EditInterview = ({ setNotification, interview, setOpenEditor }:
  { setNotification: (message: string, type: 'success' | 'error') => void, interview: InterviewType, setOpenEditor: React.Dispatch<React.SetStateAction<boolean>> }) => {

  const [title, setTitle] = useState('');
  const [profile, setProfile] = useState('');
  const [description, setDescription] = useState('');

  const navigate = useNavigate()

  const [editInterview, result] = useMutation(EDIT_INTERVIEW, {
    onError: (error) => {
      //const messages = error.graphQLErrors[0].message
      setNotification(error.message, 'error');
    },
  })
  const [deleteInterview] = useMutation(DELETE_INTERVIEW, {
    refetchQueries: [{ query: ALL_INTERVIEWS }],
    onError: (error) => {
      setNotification(error.message, 'error');
    }
  })
  const editorRef = useRef<LexicalEditor | null>(null);

  const submit = (event: SyntheticEvent) => {
    event.preventDefault()
    const latestEditorState = editorRef.current?.getEditorState();
    const textContent = latestEditorState?.read(() =>
      $getRoot().getTextContent()
    );
    const description = textContent;
    editInterview({
      variables: {
        id: interview.id, title: title, profile: profile, description: description
      }
    })

    if (!result.error) {
      setOpenEditor(false);
    }
    setNotification('수정되었습니다.', 'success');
  }

  const submitDeleteInterview = async (id: number) => {
    const result = await deleteInterview
      ({
        variables: {
          id: id
        }
      })

    if (result.data) setNotification('삭제되었습니다.', 'success');
    navigate('/interviews')
  }

  useEffect(() => {
    if (result.data && result.data.editDescription === null) {
      setNotification('Not Found', 'error')
    }
    setTitle(interview.title.toString())
    setProfile(interview.profile.toString())
  }, [result.data]) // eslint-disable-line 

  return (
    <div>
      <h2>{interview.title} 인터뷰 수정하기</h2>

      <form onSubmit={submit}>
        <div>
          인터뷰 제목 <input
            value={title}
            onChange={({ target }) => setTitle(target.value)}
          />
        </div>      
        <div>
          프로필 <input
            value={profile}
            onChange={({ target }) => setProfile(target.value)}
          />
        </div>        
        <div className="editorWrapper">
          {/* <input
            value={phone}
            onChange={({ target }) => setPhone(target.value)}
          /> */}
          <Editor ref={editorRef} description={interview.description} />
        </div>
        <button type='submit'>수정</button>
      </form>
      <button type='button' onClick={() => setOpenEditor(false)}>취소</button>
      <hr />
      <button onClick={() => submitDeleteInterview(interview.id)}>{"삭제"}</button>
      <hr />
    </div>
  )
}

export default EditInterview;