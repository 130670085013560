import { useState, SyntheticEvent, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { ADD_INTERVIEW, ALL_INTERVIEWS } from '../queries'
import { Editor } from './Editor/Editor';
import { $getRoot, LexicalEditor } from 'lexical';
import { CLEAR_EDITOR_COMMAND } from 'lexical';
import { useNavigate } from 'react-router-dom';


const InterviewForm = ({ setNotification }: { setNotification: (message: string, type: 'success' | 'error') => void }) => {
  const [title, setTitle] = useState('')
  const [profile, setProfile] = useState('')
  const [description, setDescription] = useState('')

  const editorRef = useRef<LexicalEditor | null>();

  const [createInterview] = useMutation(ADD_INTERVIEW, {
    update: (cache, response) => {
      cache.updateQuery({ query: ALL_INTERVIEWS }, ({ allInterviews }) => {
        return {
          allInterviews: allInterviews?.concat(response.data.addInterview),
        }
      })
    },
  })
  const navigate = useNavigate()
  const submit = async (event: SyntheticEvent) => {
    event.preventDefault()
    
    const latestEditorState = editorRef.current?.getEditorState();
    const textContent = latestEditorState?.read(() =>
      $getRoot().getTextContent()
    );
    const description = textContent;
    const result = await createInterview({ variables: { title, profile, description } })

    if(result.data) {
    setTitle('')
    setProfile('')
    setDescription('')
    setNotification(`${title} 추가되었습니다`, 'success')
    navigate('/interviews')
    }

    if (editorRef.current !== null)
    editorRef.current?.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
  }

  return (
    <div style={{marginLeft: '1.5%', marginRight: '1.5%'}}>
      <h2>인터뷰 추가하기</h2>
      <form onSubmit={submit}>
        <div>
          제목 <input value={title}
            onChange={({ target }) => setTitle(target.value)}
          />
        </div>
        <div>
          프로필 <input value={profile}
            onChange={({ target }) => setProfile(target.value)}
          />
        </div>
        <div  className="editorWrapper">
          {/* <input value={description}
            onChange={({ target }) => setDescription(target.value)}
          /> */}
          <Editor ref={editorRef} />
        </div>
        {/* <div>어플리케이션: {apps.join(' ')}</div> */}
        {/* <div>
          앱 <input value={app}
            onChange={({ target }) => setApp(target.value)}
          />
          <button onClick={addApp} type="button">
            앱 추가
          </button>
        </div> */}
      <hr/>
      <button type='submit' id='submit-button'>추가!</button>
      </form>
    </div>
  )
}

export default InterviewForm;