import { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { FIND_INTERVIEW } from '../queries'
import { useNavigate, useParams } from 'react-router-dom'
import Button from '@mui/material/Button';
import Loading from './Loading'
import Time from './Time';
import EditInterview from './EditInterview';

const InterviewInfo = ({ setNotification, token }: {
    setNotification: (message: string, type: 'success' | 'error') => void, token: string | null
}) => {
    const navigate = useNavigate()
    const [openEditor, setOpenEditor] = useState(false);// onClose, , onClose: () => void

    let { id } = useParams()
    id ??= ""
    const { data, loading, error } = useQuery(FIND_INTERVIEW, {
        variables: { id: Number(id) },
        skip: !id
    })

    useEffect(() => {
        if (data?.findInterview?.title) {
            document.title = data.findInterview.title
        }
    }, [data?.findInterview?.title]);

    if (loading) return <Loading />
    if (error) return <p>ERROR: {error.message}</p>;
    if (!data) return <p>Not found</p>;
    return (
        data.findInterview &&
        <div style={{ marginLeft: window.innerWidth > 700 ? '1%': '3%', marginBottom: "2%", maxWidth: window.innerWidth > 700 ? '40%' : '100%', // Responsive width
            marginRight: window.innerWidth > 700 ? '10%' : '5%' }}>
            {openEditor ? null :
                <div>
                    {/* <div style={{display: 'flex', justifyContent: 'space-between'}}> */}
                    <h2 style={{}}>{`${data.findInterview.title}`}
                    </h2>
                    <h3 style={{}}>{`『${data.findInterview.profile}`}
                    </h3>
                    <hr />
                    <div style={{ whiteSpace: 'break-spaces', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
                        {data.findInterview.description}
                    </div>
                    <hr />
                    <br />
                    
                    <div style={{ display: 'flex', justifyContent: document.documentElement.clientWidth <= 1300 ? 'flex-end' : 'flex-start' }}>
                        {token ? <Button variant="contained" onClick={() => setOpenEditor(!openEditor)}
                        >수정</Button> : null}
                        <Button variant="contained" color="error" onClick={() => navigate('/interviews')}
                        >닫기</Button>
                    </div>
                    <Time createdAt={data.findInterview.createdAt} updatedAt={data.findInterview.updatedAt}/>
                </div>}
            {openEditor ? <EditInterview setNotification={setNotification} interview={data.findInterview} setOpenEditor={setOpenEditor} /> : null}
        </div>
    )
}

export default InterviewInfo;